import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PdfNapkin from './PdfNapkin';

const App = () => (
    <Router>
        <Route path="/" component={PdfNapkin} />
    </Router>
);

export default App;