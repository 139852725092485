import * as React from 'react';
import { PaginationControlProps } from '../models/Types'
import './PaginationControl.scss';

export default function PaginationControl(props: PaginationControlProps) {
    return (
        <input type="button" 
            className="pagination-control"
            value={props.label} 
            title={props.title}
            disabled={props.isDisabled}
            onClick={props.handler} />        
    );
}