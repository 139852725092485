import * as React from 'react';
import { fabric as Fabric } from "fabric";
import { PdfNapkinProps, PdfNapkinState } from './components/models/Types'
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Container, Row, Col } from 'reactstrap';

class PdfNapkin extends React.Component<PdfNapkinProps, PdfNapkinState> {
    private _canvas: any; // Should be Fabric.Canvas but @types is vastly outdated
    private _images: Array<string>;
    private _pageStates: any = {};
    private _totalPages: number;
    private _serverUrl: string;
    private _origX: number;
    private _origY: number;
    private _shape: any;
    private _isDown: boolean = false;
    private _isBrushMode: boolean = false;
    private _isSelectionMode: boolean = false;
    private _isTextboxEditExitAction: boolean = false;
    private _shapeFillColor: string|null = null;
    private _shapeStrokeColor: string = "#0A71E9";

    // private _startCoords: Array<number>;
    // private _endCoords: Array<number>;

    constructor(props: PdfNapkinProps) {
        super(props);
        this._serverUrl = `${process.env.REACT_APP_SERVER_URL}`;
        this.state = {
            currentPage: 0,
            isReady: false,
            selectedTool: "select"
        };

        this.handleNextPage = this.handleNextPage.bind(this);
        this.handlePrevPage = this.handlePrevPage.bind(this);
        this.handleModifyBrushColor = this.handleModifyBrushColor.bind(this);
        this.handleModifyBrushWidth = this.handleModifyBrushWidth.bind(this);
        this.handleModifyShapeFillColor = this.handleModifyShapeFillColor.bind(this);
        this.handleModifyStrokeColor = this.handleModifyStrokeColor.bind(this);
        this.handleShapeSelection = this.handleShapeSelection.bind(this);
        this.handleGoToPage = this.handleGoToPage.bind(this);
        this.handleCanvasMouseDown = this.handleCanvasMouseDown.bind(this);
        this.handleCanvasMouseMove = this.handleCanvasMouseMove.bind(this); 
        this.handleCanvasMouseOver = this.handleCanvasMouseOver.bind(this); 
        this.handleCanvasMouseUp = this.handleCanvasMouseUp.bind(this);
        this.handleCanvasSelectionStart = this.handleCanvasSelectionStart.bind(this);
        this.handleCanvasSelectionEnd = this.handleCanvasSelectionEnd.bind(this);
        this.handleCanvasKeydown = this.handleCanvasKeydown.bind(this);
        this.handleCanvasObjectScaling = this.handleCanvasObjectScaling.bind(this);
        this.handleTextboxEditingExit = this.handleTextboxEditingExit.bind(this);
    }

    public componentDidMount() {
        fetch(`${this._serverUrl}/svg/list`)
            .then(res => res.json())
            .then(
                result => {
                    this._images = result.paths;
                    this._totalPages = result.paths.length;

                    // Now that we have all the possible pages, we can safely render
                    this.setState({ isReady: true });

                    this.initCanvas();
                },
                error => {}
            )
    }

    public render() {
        if (!this.state.isReady) return null;

        return (
            <Container fluid={true}>                
                <Header 
                    selectedTool={this.state.selectedTool} 
                    handleShapeSelection={this.handleShapeSelection} />

                <Row className="justify-content-center">
                    <Col className="canvas-container flex-grow-0"
                        onKeyDown={this.handleCanvasKeydown}
                        tabIndex={0}>
                        <canvas id="c" height="1056" width="816" className="shadow rounded"></canvas>
                    </Col>
                </Row>

                <Footer 
                    pagination={{
                        currentPage: this.state.currentPage,
                        totalPages: this._totalPages,
                        handleGoToPage: this.handleGoToPage 
                    }}/>
            </Container>
        );
    }
    
    public initCanvas() {
        const canvas: any = new Fabric.Canvas("c", { isDrawingMode: false });

        canvas.freeDrawingBrush.width = 5;
        canvas.freeDrawingBrush.color = '#0A71E9';

        canvas.on('mouse:up', this.handleCanvasMouseUp);
        canvas.on('mouse:down', this.handleCanvasMouseDown);
        canvas.on('mouse:move', this.handleCanvasMouseMove);
        canvas.on('mouse:over', this.handleCanvasMouseOver);
        canvas.on('selection:created', this.handleCanvasSelectionStart);
        canvas.on('selection:cleared', this.handleCanvasSelectionEnd);
        canvas.on('object:scaling', this.handleCanvasObjectScaling);

        this._canvas = canvas;

        this.loadPage(this.state.currentPage);
    }

    public loadPage(page: number) {
        this._canvas.clear().renderAll();
        this._canvas.setBackgroundImage(
            `${this._serverUrl}/${this._images[page]}`, 
            this._canvas.renderAll.bind(this._canvas)
        );        
    }

    public checkPageState(page: number) {
        return !!this._pageStates[page];
    }

    public savePageState(page: number) {
        this._pageStates[page] = this._canvas.toJSON();
    }

    public getPageState(page: number) { 
        return this._pageStates[page]; 
    }

    public restorePageState(page: number) {
        this._canvas.loadFromJSON(this.getPageState(page));
    }

    public handleCanvasKeydown(event: React.KeyboardEvent<HTMLInputElement>) {
        console.log("Key Down: ", event.key, this._canvas.getActiveObjects());
        
        if (event.key === "Delete") {
            this._canvas.getActiveObjects().forEach((o: any) => {
                this._canvas.remove(o);
            });
            this._canvas.discardActiveObject().renderAll();
        }
    }
    public handleCanvasSelectionStart(o: any) {
        console.log('Selection started: ', o);
        this._isSelectionMode = true;
    }
    public handleCanvasSelectionEnd(o: any) {
        console.log('Selection ended: ', o);
        this._isSelectionMode = false;
    }

    public handleCanvasObjectScaling(o: any) {        
        const shape = o.target;
        let settings;

        if (o.target instanceof Fabric.Rect) {
            settings = { 
                height: shape.height * shape.scaleY,
                width: shape.width * shape.scaleX,
                scaleX: 1, 
                scaleY: 1,
                strokeWidth: 5
            };
            console.log("Resizing Rectangle: ", settings.height, settings.width, " => ", o.transform.height, o.transform.width);
        }
        else if (o.target instanceof Fabric.Ellipse) {
            settings = { 
                rx: shape.getRx(),
                ry: shape.getRy(),
                scaleX: 1, 
                scaleY: 1,
                strokeWidth: 5
            };
        }
        console.log(settings, o);
        shape.set(settings);
    }

    public handleCanvasMouseOver(o: any) {
        if (o.target) {
            let targetType;
            if (o.target instanceof Fabric.Rect) targetType = "Rectangle";
            else if (o.target instanceof Fabric.Ellipse) targetType = "Ellipse";
            else if (o.target instanceof Fabric.Textbox) targetType = "Textbox";
            else if (o.target instanceof Fabric.Path) targetType = "Freehand";
            else targetType = "UNKNOWN";
            console.log("Mouse Over: ", targetType, o);
        }
    }
    public handleCanvasMouseDown(o: any) {
        if (this._isTextboxEditExitAction
            ||this._isBrushMode 
            || this._isSelectionMode) { 
            this._isTextboxEditExitAction = false; 
            return; 
        }
        
        console.log("Mouse Down: ", o);
        this._isDown = true;

        const pointer = this._canvas.getPointer(o.e);
        this._origX = pointer.x;
        this._origY = pointer.y;

        const shape = this.getShapeByTool(this.state.selectedTool);
        if (shape) {
            this._shape = shape;
            this._canvas.add(shape);

            if (shape instanceof Fabric.Textbox) {
                shape.set({
                    left: this._origX,
                    top: this._origY
                }); 
                shape.enterEditing();
                shape.selectAll();
                this._canvas.setActiveObject(shape);
                shape.on('editing:exited', this.handleTextboxEditingExit);
            } else if (shape instanceof Fabric.Rect) {
                shape.set({
                    left: this._origX,
                    top: this._origY,
                    width: 0,
                    height: 0
                });
            } else if (shape instanceof Fabric.Ellipse) {
                shape.set({
                    left: this._origX,
                    top: this._origY,
                    rx: 0,
                    ry: 0
                });
            }
        }
    }

    public handleTextboxEditingExit(o: any) {
        this._isTextboxEditExitAction = true;
    }

    public handleCanvasMouseMove(o: any) {  
        if (!this._isDown) return;
        if (this._isBrushMode || this._isSelectionMode || !this._shape) return;

        const pointer = this._canvas.getPointer(o.e);

        const settings = { 
            left: (this._origX > pointer.x) ? Math.abs(pointer.x) : this._shape.get('left'),
            top: (this._origY > pointer.y) ? Math.abs(pointer.y) : this._shape.get('top'),
            scaleX: 1,
            scaleY: 1
        };
        const x = Math.abs(this._origX - pointer.x),
            y = Math.abs(this._origY - pointer.y);
            
        if (this._shape instanceof Fabric.Rect) {
            settings["width"] = x;
            settings["height"] = y;
        } else if (this._shape instanceof Fabric.Ellipse) {
            settings["rx"] = (x / 2);
            settings["ry"] = (y / 2);
        }

        this._shape.set(settings);
        this._canvas.renderAll();
    }

    public handleCanvasMouseUp(o: any) {
        this._isDown = false;

        if (this._shape) {
            this._shape.setCoords();
            this._shape = null;
        }
    }

    public handleModifyBrushWidth(event: React.ChangeEvent<HTMLInputElement>) {
        const width = parseInt(event.target.value);
        this._canvas.freeDrawingBrush.width = width;
    }
    public handleModifyStrokeColor(event: React.ChangeEvent<HTMLInputElement>) {
        const strokeColor = event.target.value;
        this._shapeStrokeColor = strokeColor;
    }
    public handleModifyShapeFillColor(event: React.ChangeEvent<HTMLInputElement>) {
        const fillColor = event.target.value;
        this._shapeFillColor = fillColor;
    }
    public handleModifyBrushColor(event: React.ChangeEvent<HTMLInputElement>) {
        const colorCode = event.target.value;
        this._canvas.freeDrawingBrush.color = colorCode;
    }

    public handleNextPage(event: React.MouseEvent<HTMLInputElement>) {
        const page = this.state.currentPage + 1;
        this.handleGoToPage(page);
    }

    public handlePrevPage(event: React.MouseEvent<HTMLInputElement>) {
        const page = this.state.currentPage - 1;
        this.handleGoToPage(page);
    }

    public handleGoToPage(page: number) {
        this.savePageState(this.state.currentPage);

        if (!this.checkPageState(page)) {
            this.loadPage(page);
        } else {
            this.restorePageState(page);
        }

        // Triggers update to pagination control states
        this.setState({ currentPage: page });
    }

    public handleShapeSelection(event: React.ChangeEvent<HTMLInputElement>) {
        const selectedTool = event.target.value;   
        this._canvas.isDrawingMode = this._isBrushMode = (selectedTool === "brush");
        this._canvas.selection = this._isSelectionMode = (selectedTool === "select");    
        // this._canvas._isTextMode = (selectedTool === "text");

        console.log("changed to ", selectedTool)

        this.setState({ selectedTool });
    }

    public getShapeByTool(tool: string): any {
        switch(tool) {
            case "rectangle":
            return new Fabric.Rect({
                left: this._origX,
                top: this._origY,
                originX: 'left',
                originY: 'top',
                angle: 0,
                fill: this._shapeFillColor || '',
                stroke: this._shapeStrokeColor,
                strokeWidth: 5,
                transparentCorners: false
            });
            case "circle":
            return new Fabric.Ellipse({
                left: this._origX,
                top: this._origY,
                originX: 'left',
                originY: 'top',
                angle: 0,
                fill: this._shapeFillColor || '', 
                stroke: this._shapeStrokeColor,
                strokeWidth: 5,
                transparentCorners: false
            });
            case "text":
            return new Fabric.Textbox('This is a test', { 
                left: this._origX,
                top: this._origY,
                originX: 'left',
                originY: 'top',
                width: 200,
                fontSize: 14,
                fontFamily: "IBM Plex Sans",
                fill: '#0A71E9'
            });
            default: return null;
        }
    }
}

export default PdfNapkin;