import * as React from 'react';
import { FooterProps, FooterState } from '../models/Types';
import PaginationControl from '../PaginationControl/PaginationControl';
import { Row, Col } from 'reactstrap';
import './Footer.css';

class Footer extends React.Component<FooterProps, FooterState> {
    constructor (props: FooterProps) {
        super(props);
    }

    public render() {
        return (
            <Row className="footer">
                <Col xs="3">
                    <div className="signature"></div>
                </Col>
                <Col></Col>
                <Col xs="3" className="text-right">
                    <div className="pagination-controls">
                        <PaginationControl 
                            label="<"
                            title="Previous Page"
                            isDisabled={this.props.pagination.currentPage == 0}
                            handler={() => this.props.pagination.handleGoToPage(this.props.pagination.currentPage - 1)} />
                        <PaginationControl 
                            label=">"
                            title="Next Page"
                            isDisabled={(this.props.pagination.currentPage + 1) == this.props.pagination.totalPages}
                            handler={() => this.props.pagination.handleGoToPage(this.props.pagination.currentPage + 1)} />
                    </div>
                </Col>
            </Row>
        );
    }
}

export default Footer;