import * as React from 'react';
import { HeaderProps, HeaderState } from '../models/Types';
import { Row, Col, Button } from 'reactstrap';
import './Header.css';
import CanvasControl from '../CanvasControl/CanvasControl';

class Header extends React.Component<HeaderProps, HeaderState> {
    constructor (props: HeaderProps) {
        super(props);
    }

    public render() {
        return (
            <Row className="header">
                <Col xs="3">
                    <div className="logo"></div>
                </Col>
                <Col className="d-inline-flex justify-content-center">
                    <CanvasControl 
                        title="Select"
                        icon="icon-select"
                        toolName="select"
                        {...this.props} />

                    <CanvasControl 
                        title="Type"
                        icon="icon-type"
                        toolName="text"
                        {...this.props} />

                    <CanvasControl 
                        title="Draw"
                        icon="icon-pencil"
                        toolName="brush"
                        {...this.props} />
                        
                    <CanvasControl 
                        title="Rectangle"
                        icon="icon-rectangle"
                        toolName="rectangle"
                        {...this.props} />
                        
                    <CanvasControl 
                        title="Circle"
                        icon="icon-circle"
                        toolName="circle"
                        {...this.props} />
                </Col>
                <Col xs="3" className="text-right">
                    <Button outline color="primary" className="share-button"
                        onClick={() => alert("Feature Coming Soon!")}>Share</Button>
                </Col>
            </Row>
        );
    }
}

export default Header;