import * as React from 'react';
import { CanvasControlProps, CanvasControlState } from '../models/Types'
import './CanvasControl.scss';

class CanvasControl extends React.Component<CanvasControlProps, CanvasControlState> {
    constructor (props: CanvasControlProps) {
        super(props);
    }

    public render() {
        return (
            <div className={`canvas-control ${(this.props.selectedTool === this.props.toolName) ? 'active' : ''}`}>
                <label>
                    <div>
                        <div className={`icon ${this.props.icon}`}></div>
                        <div className="title">{this.props.title}</div>
                    </div>
                    <input 
                        hidden
                        type="radio" name="control" 
                        value={this.props.toolName} 
                        onChange={this.props.handleShapeSelection} />
                </label>
            </div>
        );
    }
}

export default CanvasControl;